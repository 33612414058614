.dr-chart-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  max-width: 60vw;

  background: $dr-color-white;

  border: 1px solid $dr-color-gray-400;
  border-radius: 8px;
  box-sizing: border-box;

  /* Drop Down Shadow */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 6px rgba(0, 0, 0, 0.1);

  &.drm--right {
    transform: translate(50%, 50%);
  }

  &.drm--left {
    transform: translate(-50%, 50%);
  }

  &.drm--small {
    padding: 8px 16px;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    width: 100%;

    border-bottom: 1px solid $dr-color-gray-400;
    padding-bottom: 4px;
    margin: 0 0 6px;
  }

  ul li {
    margin: 0 0 2px;
    padding: 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 748px) {

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba($dr-color-gray-400, 0.3);
        margin: 5px 0;
        padding-bottom: 5px;
      }
    }
  }

  .drm--text,
  footer {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    word-break: break-word;

    /* Gray/800 */
    color: $dr-color-gray-800;
  }

  .drm--text {
    padding-bottom: 2px;
  }

  footer {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    word-break: keep-all;
  }

  ul li .drm--color {
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    width: 12px;
    min-width: 12px;
    height: 12px;
    min-height: 12px;
  }
}

@media screen and (max-width: 748px) {
  .dr-chart-tooltip-wrapper {
    transform: none !important;

    .dr-chart-tooltip {
      transform: none !important;
    }
  }
}
