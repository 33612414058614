.dr-login-page {
  background: $dr-color-gray-100;

  .drp-container {
    margin: auto;
    text-align: center;
    background: $dr-color-white;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0 2px 6px 0 rgba(38, 49, 88, 0.2);

    img {
      margin-bottom: 40px;
      max-width: 200px;
    }

    header {
      @include dr-text(18px, 500);
    }

    main {
      text-align: center;
      padding-top: 40px;

      button {
        @include dr-text(24px, 500);
        padding: 16px 24px;

        border-radius: 8px;
        color: $dr-color-white;
        background: $dr-color-brand-secondary;

        &:hover {
          background: $dr-color-brand-primary;
        }
      }
    }

    .drp-error {
      padding: 10px 20px;
      border: 1px solid #d28187;
      border-radius: 4px;
      background: #e8c0c3;
      max-width: 400px;
      @include dr-text(12px, 500);
    }
  }
}
