//noinspection SassScssResolvedByNameOnly
.dr-select-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  color: $dr-color-gray;
  gap: 14px;
  position: relative;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    background-color: transparent;
    border: none;
    font: inherit;
    gap: 8px;

    .drp-icon {
      transition: transform 0.3s ease;
      fill: none;
      width: 24px;
      height: 24px;

      &:hover {
        cursor: pointer;
      }

      > path {
        stroke: $dr-color-gray;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }

  > ul {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
    max-width: 200px;

    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 4px;
    z-index: 2;

    background: $dr-color-white;
    border: 1px solid $dr-color-gray-400;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > li {
      display: flex;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      color: $dr-color-gray-800;
      cursor: pointer;
      padding: 6px 16px;

      &:hover {
        background-color: $dr-color-gray-100;
      }

      &.lgs--active {
        background-color: $dr-color-brand-secondary;
        color: $dr-color-white;
      }
    }

    .drp-option-break {
      display: flex;
      align-self: center;
      width: 80%;
      border-bottom: 1px solid $dr-color-gray-400;
      margin: 6px 0;
    }
  }

  &.lgs--opened {
    button .drp-icon {
      transform: rotate(180deg);
    }

    > ul {
      display: flex;
    }
  }
}
