/* @doc(group) { name: 'reset', title: 'Reset'} */

@mixin dr-adjust-device-text-size() {
    font-size: 100%;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

/* @doc(mixin) {
  description: 'Remove default outline.'
}
*/
@mixin dr-clean-outline() {
    outline: none;
    &:focus,
    &:hover {
        outline: none;
    }
}

/* @doc(mixin) {
  description: 'Inherit text properties.'
}
*/
@mixin dr-inherit-text() {
    font: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    font-weight: inherit;
    text-decoration: inherit;
    text-transform: inherit;
}

/* @doc(mixin) {
  description: [
   'Remove browser heading styles.',
   'Should be applied to {hi}'
  ]
}
*/
@mixin dr-clean-heading() {
    margin: 0;
    padding: 0;
    font: inherit;
    color: inherit;
    text-decoration: inherit;
    text-transform: inherit;
}

/* @doc(mixin) {
  description: 'Remove default browser list styles'
}
*/
@mixin dr-clean-list() {
    //&
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-inline-start: 0;

    > li {
        margin: 0;
        padding: 0;
    }
}

/* @doc(mixin) {
  description: 'Remove default browser button styles.'
}
*/
@mixin dr-clean-button() {
    background: transparent;

    border: 0;
    padding: 0;
    //margin: 0; - no need to reset margin - creates issues with inheritance

    font: inherit;
    color: inherit;
    text-decoration: inherit;
    text-transform: inherit;
}

/* @doc(mixin) {
  description: 'Remove default browser fieldset styles.'
}
*/
@mixin dr-clean-fieldset() {
    padding: 0;
    margin: 0;
    border: 0;
}

/* @doc(mixin) {
  description: 'Remove default browser legend styles.'
}
*/
@mixin dr-clean-legend() {
    padding: 0;
    margin: 0;
    border: 0;
}

/* @doc(mixin) {
  description: [
    'Remove default numeric input styles.',
    '(remove spin button)'
  ]
}
*/
@mixin dr-clean-numeric-input() {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button {
        display: none;
    }
    &::-webkit-inner-spin-button {
        display: none;
    }
}

/* @doc(mixin) {
  description: [
    'Fix placeholder styles (https://css-tricks.com/almanac/selectors/p/placeholder/).',
    '(default placeholder opacity)'
  ]
}
*/
@mixin dr-clean-input() {
    // It is important to separate different declarations for placeholder!
    &::placeholder {
        opacity: 1;
    }

    &::-ms-input-placeholder {
        opacity: 1;
    }

    &:-ms-input-placeholder {
        opacity: 1;
    }
}

@mixin dr-clean-link {
    text-decoration: none;
    color: inherit;
}

/* @doc(mixin) { description: '*root reset' } */
@mixin dr-reset() {
    // cursor
    button,
    a {
        cursor: pointer;
    }

    button {
        @include dr-clean-button();
    }

    a {
        @include dr-clean-link();
    }

    // text-inherit
    input,
    textarea,
    select,
    button,
    a {
        @include dr-inherit-text();
    }

    // outline
    button,
    a,
    ul,
    li,
    div,
    tr,
    input,
    textarea {
        @include dr-clean-outline();
    }

    // block elements box-size
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Block-level_elements
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Introduction_to_the_CSS_box_model
    div,
    p,
    pre,
    table,
    form,
    fieldset,
    main,
    header,
    footer,
    nav,
    section,
    ul,
    li,
    ol,
    dl,
    dt,
    dd,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    article,
    aside,
    details,
    dialog,
    figcaption,
    figure {
        box-sizing: border-box;
    }

    // button pointer events on span
    button[disabled] {
        pointer-events: none;

        > * {
            pointer-events: none;
        }
    }

    // Mac bold font rendering fix
    *,
    *:before,
    *:after {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: geometricPrecision;
    }
}
