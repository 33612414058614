.dr-export-button {
  color: $dr-color-black;
  font: inherit;
  @include dr-text(14px, 500, 20px);

  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid $dr-color-gray-400;
  background: $dr-color-white;

  &:hover {
    cursor: pointer;
    color: $dr-color-white;
    background: $dr-color-brand-secondary;
  }
}
