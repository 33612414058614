//noinspection SassScssResolvedByNameOnly
.dr-app {
  $headerSize: 64px;

  max-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    height: $headerSize;
    min-height: $headerSize;
    background-color: $dr-color-white;
    box-shadow: inset 0px -1px 0px $dr-color-gray-400;
    padding: 12px 32px 12px 16px;

    img {
      width: 200px;
    }
  }

  > aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    top: $headerSize;
    width: 80px;
    height: calc(100% - #{$headerSize});
    gap: 16px;

    padding: 16px;
    background-color: $dr-color-white;
    box-shadow: inset -1px 0px 0px $dr-color-gray-400;

    .dr-icon-button {
      width: 48px;
      height: 48px;
      padding: 12px;
      background: $dr-color-gray-100;
      border-radius: 8px;
      font-size: 24px;
      color: $dr-color-gray-800;
      stroke: $dr-color-gray-800;

      &:hover,
      &.drs--active {
        background: $dr-color-brand-secondary;
        color: $dr-color-white;
        stroke: $dr-color-white;
      }
    }
  }

  > main {
    width: 100%;
    background-color: $dr-color-gray-100;
    padding: 32px 32px 32px 112px; // left padding = sidebar width + 32px
    overflow: hidden auto;
    flex-grow: 1;
    @include dr-layout();

    h1 {
      @include dr-text(32px, 500, 150%);
      margin: 0;

      color: $dr-color-black;
    }
  }

  @media screen and (max-width: 748px) {
    > header {
      img {
        width: 200px;
      }
    }

    > aside {
      position: unset;
      flex-direction: row;
      height: auto;
      box-shadow: inset 0px -1px 0px $dr-color-gray-400;
      gap: 20px;
      width: 100%;
    }

    > main {
      padding: 16px;
      > header {
        flex-direction: column;
        align-items: flex-start;

        .drp-broker-selector {
          align-items: flex-start;
        }
      }
    }
  }
}
