//noinspection SassScssResolvedByNameOnly
.dr-check-box {
    cursor: pointer;
    display: flex;
    align-items: center;

    .drp-check-mark {
        display: inline-flex;
        width: 14px;
        height: 14px;
        background: $dr-color-white;
        border: 1px solid $dr-color-gray-400;
        border-radius: 4px;
        align-items: center;
        justify-content: center;

        color: $dr-color-white;
        fill: $dr-color-white;
        @include dr-text(19px, 600);

        svg {
            width: 12px;
            height: 12px;
        }

        &.drs--checked {
            background-color: #42a9e0;
            border: 1px solid #42a9e0;
        }
    }

    .drp-label {
        margin-left: 8px;
        color: $dr-color-gray;
        @include dr-text(14px, 500, 24px);
    }
}
