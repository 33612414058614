@keyframes dr-loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dr-loader {
    width: 100%;
    height: 100%;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .drp-spinner {
        border: 4px solid $dr-color-brand-secondary;
        border-top-color: transparent;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: dr-loader-spin 0.6s linear infinite;
    }

    .drp-label {
        margin-top: 10px;
        @include dr-text(14px, semi-bold);
    }

    &.drm--page-loader {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.1);
    }
}
