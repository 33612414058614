$font-url: './assets/Inter-VariableFont_slnt,wght.ttf' !default;

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local('Inter'), url(#{$font-url}) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    src: local('Inter'), url(#{$font-url}) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local('Inter'), url(#{$font-url}) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    src: local('Inter'), url(#{$font-url}) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: local('Inter'), url(#{$font-url}) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    src: local('Inter'), url(#{$font-url}) format('truetype');
}
