//noinspection SassScssResolvedByNameOnly
@mixin dr-layout() {
  $gap: 24px;

  display: flex;
  flex-direction: column;
  gap: $gap;

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    .dr-filters-wrapper {
      display: flex;
      gap: 20px;

      .drp-broker-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        gap: 22px;
      }

      @media screen and (max-width: 748px) {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 910px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border: 1px solid $dr-color-gray-400;
    border-radius: 8px;
    background: $dr-color-white;

    > header {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      @include dr-text(20px, 500, 120%);
      color: $dr-color-black;
      width: 100%;
    }

    > footer {
      display: flex;
      justify-content: space-between;
      padding: 12px 24px;
      @include dr-text(20px, 500, 120%);
      color: $dr-color-black;
      width: 100%;
    }

    .drp-body {
      padding: 24px;
      width: 100%;
      height: 340px;
      max-height: 340px;
      overflow: visible;

      svg {
        overflow: visible;
      }

      .drp-error {
        display: inline-block;
        @include dr-text(14px, 500);
      }

      &.drs--error {
        height: auto;
      }
    }

    &.drt--table .drp-body {
      padding: 0;
      overflow: auto;

      .drp-error {
        padding: 24px;
      }
    }

    &.drt--chart header,
    &.drt--table header {
      border-bottom: 1px solid $dr-color-gray-400;
    }

    &.drt--chart footer,
    &.drt--table footer {
      border-top: 1px solid $dr-color-gray-400;
    }

    &.drt--number .drp-body {
      height: auto;
      max-height: unset;
      padding: 0 24px 16px;
      @include dr-text(48px, 600, 100%);
    }
  }

  .drt--two-columns {
    display: flex;
    flex-direction: row;
    gap: $gap;

    > * {
      flex-grow: 1;
      width: calc(50% - 12px);
    }

    @media screen and (max-width: 920px) {
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  }
}
