.dr-data-table {
    width: 100%;
    border-spacing: 0;

    .drp-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        border-bottom: 1px solid $dr-color-gray-400;
        height: 61px;

        .drp-order-number {
            @include dr-text(14px, 400);
            padding-left: 8px;
            min-width: 50px;
            max-width: 50px;
            box-sizing: border-box;
            text-align: center;
            color: $dr-color-gray-600;
        }

        .drp-name {
            flex-grow: 1;
            @include dr-text(14px, 600);
        }

        .drp-value {
            @include dr-text(14px, 400);
            padding-right: 24px;
            padding-left: 10px;

            .drm--currency {
                margin-left: 5px;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }
}
