//noinspection SassScssResolvedByNameOnly
.dr-user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  column-gap: 16px;

  .drp-avatar, .drp-integrations-menu {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .drp-avatar {
    &:hover {
      border-color: $dr-color-brand-secondary;
    }
  }

  .drp-integrations-menu {
    background-color: rgba($dr-color-gray-300, 0.35);
    padding: 9px;
    position: relative;

    &:hover {
      background-color: $dr-color-gray-200;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: $dr-color-gray-600;
    }
  }

  .drp-integrations-list, .drp-user-menu {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    border: 1px solid $dr-color-gray-400;
    border-radius: 8px;
    background: $dr-color-white;
    z-index: 1;
  }

  .drp-integrations-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 300px;
    width: max-content;
    row-gap: 16px;

    padding: 24px 12px;

    .drp-icon {
      width: 90px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      img {
        padding: 8px;
        width: 56px;
        height: 56px;
        box-sizing: border-box;
        background-color: rgba($dr-color-gray-300, 0.35);
        border-radius: 12px;
        margin: 0 16px;

        &:hover {
          background-color: $dr-color-gray-400;
        }
      }
    }
  }

  .drp-user-menu {
    padding: 4px 0;

    li {
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        cursor: pointer;
        background: $dr-color-brand-secondary;
        color: $dr-color-white;
      }
    }
  }

  @media screen and (max-width: 748px) {
    .drp-avatar {
      margin-left: 0;
    }
  }
}
