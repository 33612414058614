html,
#root,
body {
    @include dr-adjust-device-text-size();
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

body {
    @include dr-reset();

    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //reset browser styles
    margin: 0;
    padding: 0;
}

* {
    @include dr-clean-outline();
}

button {
    @include dr-clean-button();
}

ul,
ol {
    @include dr-clean-list();
}
