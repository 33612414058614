$dr-color-white: #ffffff;
$dr-color-black: #000000;

$dr-color-brand-primary: #6578a8;
$dr-color-brand-secondary: #485b90;

$dr-color-gray: #4a5568;
$dr-color-gray-100: #f7fafc;
$dr-color-gray-200: #edf2f7;
$dr-color-gray-300: #e2e8f0;
$dr-color-gray-400: #cbd5e0;
$dr-color-gray-600: #718096;
$dr-color-gray-800: #2d3748;
