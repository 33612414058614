//noinspection SassScssResolvedByNameOnly
.dr-dropdown-search {
  color: $dr-color-black;
  gap: 14px;
  position: relative;

  .drp-search-component {
    display: flex;
    background-color: $dr-color-white;
    border-radius: 8px;
    border: 1px solid $dr-color-gray-400;
    font: inherit;
    width: 360px;

    .drp-input {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 8px 12px;
      border: none;
      border-radius: 8px;
      font-weight: 400;
    }

    .drp-input::placeholder {
      color: $dr-color-gray-600;
    }

    .drp-icon {
      padding: 8px;
      transition: transform 0.3s ease;
      fill: none;
      width: 24px;
      height: 24px;

      &:hover {
        cursor: pointer;
      }

      > path {
        stroke: $dr-color-gray;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }

  > ul {
    display: none;
    flex-direction: column;
    padding: 4px 0;
    width: 100%;

    max-height: 630px;
    overflow-y: auto;
    scrollbar-color: $dr-color-gray-600 $dr-color-gray-200;

    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 8px;
    z-index: 2;

    background: $dr-color-white;
    border: 1px solid $dr-color-gray-400;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > li {
      @include dr-text(14px, 400);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
      cursor: default;
      min-height: 40px;
      gap: 4px;

      &:hover {
        background-color: $dr-color-gray-100;
      }

      &.drt--parent {
        background-color: $dr-color-gray-200;
        justify-content: flex-start;
      }

      &.drs--empty:hover {
        cursor: default;
        background-color: $dr-color-white;
      }

      &.lgs--active {
        background-color: $dr-color-brand-secondary;
        color: $dr-color-white;

        .drp-option-icon {
          flex-shrink: 0;

          > path {
            fill: $dr-color-white;
          }
        }
      }

      .drp-option {
        display: flex;
        align-items: center;
        flex-grow: 1;
        gap: 8px;
      }

      .drp-option-value {
        font-weight: 500;
      }

      .drp-option-icon {
        padding: 4px;
        fill: none;
        width: 16px;
        height: 16px;

        &:hover {
          cursor: pointer;
        }

        > path {
          fill: $dr-color-black;
        }
      }
    }

    .dr-loader {
      height: 200px;
    }
  }

  > ul::-webkit-scrollbar {
    width: 4px;
  }

  > ul::-webkit-scrollbar-thumb {
    background-color: $dr-color-gray-600;
    border-radius: 4px;
  }

  > ul::-webkit-scrollbar-track {
    background-color: $dr-color-gray-200;
    border-radius: 4px;
  }

  &.lgs--opened {
    .drp-search-component .drp-icon {
      transform: rotate(180deg);
    }

    > ul {
      display: flex;
    }
  }
}
